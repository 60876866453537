<template>
  <div class="payment-form">
    <div class="amount-selection">
      <div class="select-amount-box">
        <div class="amount-item" v-for="(item, index) in selectAmountList" :key="index">
          <button @click="selectAmount(item.Money)" :class="{ selected: selectedAmount === item.Money }">
            ${{ item.Money }}<br>={{ item.balance }} game coins
          </button>
        </div>
      </div>
    </div>
    <div class="notice-box" v-if="notice">
      <div class="notice-title">
        <img src="@/assets/wow.png" alt="Good News" class="title-img" />
        GOOD NEWS...
      </div>
      <div>
        {{ notice }}
      </div>
    </div>
    <div class="custom-amount" v-if="showCustomAmount">
      <label for="custom-amount">Enter Amount:</label>
      <div>
        <input type="number" id="custom-amount" v-model.number="customAmount" @input="validateInput" readonly />
      </div>
    </div>
    <div class="ton-box">

      <TonConnectButton class="connect-button" />

    </div>

    <button v-if="wallet" class="next-button" @click="nextStep">Next</button>


    <RemindPP :isVisible="isShowErrorPP" :closeable="true" @update:isVisible="onClickBack">
      <template v-slot:body>
        {{ initErrorMsg }}
      </template>
    </RemindPP>
  </div>
</template>

<script>
import { TonConnectButton, useTonConnectUI, useTonAddress ,useTonWallet } from '@townsquarelabs/ui-vue';
import { onMounted, ref } from 'vue';
import Request from '@/utils/request';
import { event } from 'vue-gtag';
import { beginCell } from '@ton/ton'
import {useClipboard} from "@vueuse/core";
export default {
  components: {
    TonConnectButton,
  },
  setup() {
    // const tonConnectUI = useTonConnectUI();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const selectedAmount = ref(1);
    const customAmount = ref(1);
    const isShowErrorPP = ref(false);
    const isOrderInitError = ref(false);
    const initErrorMsg = ref('');
    const selectAmountList = ref([]);
    const notice = ref('');
    const showCustomAmount = ref(false);
    const orderInitCalled = ref(false);

    const sendTransaction = async () => {
      /*  const body = beginCell()
            .storeUint(0, 32) // 写入32个零位以表示后面将跟随文本评论
            .storeStringTail("hello") // 写下我们的文本评论
            .storeCoins(10000000)
            .endCell();

        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60,
          messages: [
            {
              address: "0:9084d3d304e4df8e9cc58b1cf9f8d8c944c415d7fcc5cafcc69e07736aa9ac06",
              amount: "1000000",
              // payload: "te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==",
              payload: body.toBoc().toString("base64") ,
            },
          ],
        };

        try {
          const result = await tonConnectUI.sendTransaction(transaction);
          console.log(result);
        } catch (error) {
          console.log(error);
        }*/
    };

    const validateInput = (event) => {
      const value = event.target.value;
      customAmount.value = value.replace(/[^\d]/g, '');
      selectedAmount.value = 0;
    };

    const selectAmount = (amount) => {
      selectedAmount.value = amount;
      customAmount.value = amount;
      showCustomAmount.value = false;
    };

    const nextStep = async () => {
      if (customAmount.value < 1) {
        return;
      }

      try {
        const data = await Request({
          url: '/api/order/ton',
          method: 'post',
          data: { payMoney: parseInt(customAmount.value) },
        });

        console.log(data);
        event('pay', { method: 'ton_pay' });
        // const result = await tonConnectUI.sendTransaction(data);
        // console.log(result)
        const body = beginCell()
            .storeUint(0, 32) // 写入32个零位以表示后面将跟随文本评论
            .storeCoins(data.coins)
            .storeStringTail(data.payload) // 写下我们的文本评论
            .endCell();
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 120,
          messages: [
            {
              address: data.address,
              amount: data.amount.toString(),
              // payload: "te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==",
              payload: body.toBoc().toString("base64") ,
            },
          ],
        };

        try {
          const result = await tonConnectUI.sendTransaction(transaction);
          await orderCallBack(result,data.payload)
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        isShowErrorPP.value = true;
        initErrorMsg.value = error.message;
      }
    };

    const orderCallBack = async (result,orderNumber) => {
      try {
        const data = await Request({
          url: '/api/order/ton/callback',
          method: 'post',
          data: {
            content: JSON.stringify(result),
            orderNumber:orderNumber
          },
        });
        console.log(data)
      } catch (error) {
        console.log(error)
      }
    };

    const orderInit = async () => {
      try {
        const data = await Request({
          url: '/api/order/init',
          method: 'post',
          data: {},
        });

        selectAmountList.value = data.selectAmount;
        if (data.selectAmount[0]?.Money) {
          selectedAmount.value = data.selectAmount[0].Money;
          customAmount.value = data.selectAmount[0].Money;
        }
        notice.value = data.notice;
        if (data.status === 2) {
          isOrderInitError.value = true;
          isShowErrorPP.value = true;
          initErrorMsg.value = data.msg;
        } else {
          isOrderInitError.value = false;
          isShowErrorPP.value = false;
          initErrorMsg.value = '';
        }
      } catch (error) {
        isShowErrorPP.value = true;
        initErrorMsg.value = error.message;
      }
    };

    const onClickBack = () => {
      isShowErrorPP.value = false;
    };
    const copyRawAddress = () => {
      if (window.clipboardData) {
        window.clipboardData.setData('text', rawAddress.value);
      }else {
        const {copy } = useClipboard({ legacy: true })
        copy( rawAddress.value)
      }
      alert('Copy Success');
    };

    onMounted(() => {
      if (!orderInitCalled.value) {
        orderInitCalled.value = true;
        orderInit();
      }
    });

    return {
      // sendTransaction,
      userFriendlyAddress,
      rawAddress,
      selectedAmount,
      customAmount,
      isShowErrorPP,
      isOrderInitError,
      initErrorMsg,
      selectAmountList,
      notice,
      showCustomAmount,
      validateInput,
      nextStep,
      orderInit,
      selectAmount,
      onClickBack,
      wallet,
      copyRawAddress,
      sendTransaction
    };
  },
};
</script>


<style scoped>
.connect-button {
  float: right;
  margin: 10px;

}

.ton-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.payment-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  color: #8F8F8F;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;

}


.amount-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #282828;
  margin-bottom: 50px;
  padding: 20px;
}

.amount-selection button {
  background-color: #3A3A3A;
  border: none;
  color: #D7D7D7;
  height: 125px;
  cursor: pointer;
  flex: 1;
  border-radius: 10px;
  font-size: 25px;
  line-height: 50px;
}

.amount-selection button.selected {
  border: 2px solid #15BB4F;
  color: #15BB4F;
}

.custom-amount {
  width: 100%;
  margin-bottom: 20px;
}

.custom-amount label {
  display: block;
  margin: 22px 0 22px 30px;
}

.custom-amount div {
  display: flex;
  width: 100%;
  height: 80px;
  background: #3A3A3A;
}

.custom-amount input {
  background: #3A3A3A;
  border: none;
  flex: 1;
  height: 100%;
  font-size: 24px;
  padding: 0 30px;
  color: white;
}

.next-button {
  background-color: #15BB4F;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 30px;
  color: white;
  font-size: 40px;
  height: 80px;
}

.select-amount-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.amount-item {
  display: flex;
}

.notice-box {
  background: #282828;
  margin-bottom: 50px;
  padding: 30px;
  color: #D7D7D7;
  font-size: 25px;
}

.notice-title {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.title-img {
  height: 40px;
  margin-right: 10px;
}
::v-deep .tc-root button {
  outline: none;
  width: 700px;
  height: 600px !important;
  padding: 50px;
}
</style>
